// import React from "react";
// import QRCode from "qrcode.react";

// const Scanner = () => {
//   const url = "https://flosilshop.com/fcd2024/22";

//   return (
//     <div className="my-5">
//       <QRCode value={url}
//       height="100%"
//       width="100%"
//       level="L"
//       />
//     </div>
//   );
// };

// export default Scanner;


import QRCode from "qrcode.react";

const Scanner = () => {
  const url = "https://flosilshop.com/fcd2024/22";

  return (
    <div className="my-5" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <QRCode value={url} level="L" size={512} />
    </div>
  );
};

export default Scanner;
